// import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        // barba.hooks.afterEnter((data) => {
            this.CompanyBgFix()
            this.StrengthImgChange()
            // this.balloonAnm()
        // });
        // barba.hooks.after((data) => {
            // this.CompanyBgFix()
            // this.StrengthImgChange()
            // this.balloonAnm()
        // });
    }
    CompanyBgFix() {
        const fiximg = document.querySelector(".p-company-greeting__fix");
        if(fiximg) {
            ScrollTrigger.matchMedia({
                "(min-width: 992px)": function() {
                    // const fiximg = document.querySelector(".p-company-greeting__fix");
                    gsap.to(".p-company-greeting__fix", {
                        scrollTrigger: {
                            trigger: ".p-company-greeting__fix",
                            pin: true,
                            start: 'center center',
                            endTrigger: "p-company-greeting__main",
                            end: 'center top',
                            // markers: true,
                        },
                    });
                }
            });
        }
    }
    StrengthImgChange() {
        const fixitem = document.querySelector(".p-strengths-main__imgwrapper");
        const container = document.querySelector(".p-strengths-main__container");
        const strengthsPage = document.querySelector(".p-strengths");
        
        if (strengthsPage && window.innerWidth >= 992) { // ウィンドウサイズが992px以上の場合のみ実行
            if (fixitem) {
                gsap.to(fixitem, {
                    scrollTrigger: {
                        trigger: container,
                        start: 'top+=100 center-=' + fixitem.clientHeight / 2,
                        end: 'bottom center+=' + fixitem.clientHeight / 2,
                        pin: fixitem,
                        // markers: true
                    }
                });
            }
            
            // クラスを付与する関数
            const addClassToElement = (element, className) => {
                if (!element.classList.contains(className)) {
                    element.classList.add(className);
                }
            };
            
            // クラスを削除する関数
            const removeClassFromElement = (element, className) => {
                if (element && element.classList && element.classList.contains(className)) {
                    element.classList.remove(className);
                }
            };
            
            // ビューポート内に入ったときの処理
            const handleViewportEnter = (entries) => {
                entries.forEach((entry) => {
                    const targetElement = entry.target;
                    const targetClass = targetElement.dataset.targetClass; // データ属性から対象のクラス名を取得
                    const imageElement = document.querySelector(".p-strengths-main__img");
                    
                    if (entry.isIntersecting) {
                        addClassToElement(imageElement, targetClass);
                    } else {
                        removeClassFromElement(imageElement, targetClass);
                    }
                });
            };
            
            // Intersection Observerを使用して監視する
            const observer = new IntersectionObserver(handleViewportEnter, {
                threshold: 0.5 // 要素が50%以上ビューポート内に入ったらコールバックを実行
            });
            
            // 要素を取得してIntersection Observerに追加
            const item01 = document.querySelector(".--item01");
            const item02 = document.querySelector(".--item02");
            const item03 = document.querySelector(".--item03");
            
            observer.observe(item01);
            observer.observe(item02);
            observer.observe(item03);
        }
    } 
    balloonAnm() {
        // const balloontriggers = document.querySelectorAll("[data-balloon-trigger]");
        // balloontriggers.forEach( trigger => {
        //     const balloons = document.querySelectorAll("[data-balloon-animation]");
        //     gsap.to(balloons, {
        //         opacity: 1,
        //         y: "-10%",
        //         scrollTrigger: {
        //             trigger: trigger,
        //             start: 'center center',
        //             markers: true,
        //         },
        //         stagger: {
        //             from: "start",
        //             each:0.3, 
        //         },
        //     });
        // });

        const balloontriggers = document.querySelectorAll("[data-balloon-trigger]");
        gsap.utils.toArray(balloontriggers).forEach(balloon => {
            gsap.to(balloon, {
                opacity: 1,
                y: "-10%",
                scrollTrigger: {
                    trigger: balloon,
                    start: 'center center',
                    markers: true,
                },
                stagger: {
                    from: "start",
                    each: 0.3,
                },
            });
        });
    }
}