
// import barba from '@barba/core';
import Swiper from 'swiper';
import anime, { timeline } from 'animejs';

export default class{

	constructor(){
        this.isInitIndexServiceSlider = false
        // barba.hooks.beforeOnce((data) => {
            this.heroSlider()
            this.indexServiceSlider(true)
            this.worksSlider()
            //this.serviceListSlider()
            this.blogSlider()
            this.asideSlider()
            this.serviceAsideSlider()
            // this.pickupSlider()
            // this.eventSlider()
            // this.singleSlider()
            

        // })

        // barba.hooks.leave((data) => {
        //     if(this.loop != undefined){
        //         this.cancelAnimation(this.loop)
        //         this.loop = undefined
        //     }
        // })

        // barba.hooks.after((data) => {
        //     this.heroSlider()
        //     this.indexServiceSlider(false)
        //     this.worksSlider()
        //     //this.serviceListSlider()
        //     this.blogSlider()
        //     this.asideSlider()
        //     this.serviceAsideSlider()
        //     // this.pickupSlider()
        //     // this.eventSlider()
            // this.singleSlider()
        // });
        
    }
    
    heroSlider(){
        new Swiper ('#hero--slider',{
            loop: true,
            effect :'fade',
            speed:3000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 4000
            }
        })
    }
    
    indexServiceSlider(isOnce){
        
        const indexService = document.querySelector('#p-index-service')
        if(!indexService){
            return false
        }
        const bgAnime1 = anime({
            targets:".p-index-service__slider .slide01",
        })
        
        const bgAnime2 = anime({
            targets:".p-index-service__slider .slide02",
        })

        const bgAnime3 = anime({
            targets:".p-index-service__slider .slide03",
        })

        const bgAnime4 = anime({
            targets:".p-index-service__slider .slide04",
        })

        const bgAnime5 = anime({
            targets:".p-index-service__slider .slide05",
        })
    
        const sliderDuration = 4000
        const slideChangeDuration = 800

        const sliderWrap = document.querySelector('.p-index-service__slider');
        const slider01Top = document.querySelector('.slider--item.slide01 .p-index-service__item-contents');
        const slider01Btm = document.querySelector('.slider--item.slide01 .p-index-service__group.-bottom');

        if(sliderWrap){
            const serviceContentObserver = new ResizeObserver((entries) => {
                const totalHeight = slider01Top.offsetHeight + slider01Btm.offsetHeight;
                sliderWrap.style.height = totalHeight + 'px';
            });
            serviceContentObserver.observe(slider01Top);
            serviceContentObserver.observe(slider01Btm);

            const serviceContentBtmObserver = new ResizeObserver((entries) => {
                const btmHeight = slider01Btm.offsetHeight;
                const btmWidth = slider01Btm.offsetWidth;
                const sliderBtmItems = document.querySelectorAll('.slider--item .p-index-service__group.-bottom');
                Array.from(sliderBtmItems).slice(1).forEach((sliderBtmItem) => {
                    sliderBtmItem.style.height = btmHeight + 'px';
                    sliderBtmItem.style.width = btmWidth + 'px';
                });
            });
            serviceContentBtmObserver.observe(slider01Btm);
        }

        const sliderNav = document.querySelector('.p-index-service__progress');
        const sliderImgs = document.querySelectorAll('.p-index-service__item-heading .-img');
        
        if(sliderNav){
            const serviceNavObserver = new ResizeObserver((entries) => {
                const sliderNavHeight = sliderNav.offsetHeight;
                sliderImgs.forEach((sliderImg) => {
                    sliderImg.style.height = sliderNavHeight + 'px';
                });
            });
            serviceNavObserver.observe(sliderNav);
        }
        
        const progressTit01 = document.querySelector('.p-index-service__progress-title.tit01')
        const slide01 = document.querySelector('.slider--item.slide01')
        const progressTit02 = document.querySelector('.p-index-service__progress-title.tit02')
        const slide02 = document.querySelector('.slider--item.slide02')
        const progressTit03 = document.querySelector('.p-index-service__progress-title.tit03')
        const slide03 = document.querySelector('.slider--item.slide03')
        const progressTit04 = document.querySelector('.p-index-service__progress-title.tit04')
        const slide04 = document.querySelector('.slider--item.slide04')
        const progressTit05 = document.querySelector('.p-index-service__progress-title.tit05')
        const slide05 = document.querySelector('.slider--item.slide05')

        this.cancelAnimation = (animation) => {
            let activeInstances = anime.running;
            let index = activeInstances.indexOf(animation);
            activeInstances.splice(index, 1);
        }
        
        this.LoopAnimation = (seek) => {

            if(this.loop != undefined){
                this.cancelAnimation(this.loop)
            }
            this.loop = anime.timeline({
                autoplay:false
            })

            
            this.loop
            .add({
                targets: ".p-index-service__progress .--bullet01 .slider--bullet__progress",
                strokeDashoffset:[140,0],
                easing:"linear",
                duration:sliderDuration,
                begin(){
                    progressTit01.classList.add("active");
                    slide01.classList.add("active");
                },
            })
            .add({
                targets: ".p-index-service__progress-title.tit01 .title__border",
                width:[0,'100%'],
                easing:"linear",
                duration:3000
            },'-=4000')
            .add({
                targets: ".p-index-service__progress .--bullet01 .slider--bullet__progress",
                strokeDashoffset:[0,140],
                easing:"easeOutExpo",
                duration:slideChangeDuration
            })
            .add({
                targets: ".p-index-service__progress-title.tit01 .title__border",
                width:['100%',0],
                easing:"linear",
                duration:slideChangeDuration
            },'-=800')
            .add({
                targets: slide01,
                opacity:[1,0],
                duration:slideChangeDuration,
                easing:"easeOutQuart",
                begin(){
                    bgAnime2.restart()
                    progressTit02.classList.add("active");
                    slide02.classList.add("active");
                },
                complete(){
                    bgAnime1.pause()
                    progressTit01.classList.remove("active");
                    slide01.classList.remove("active");
                }
            },'-=' + slideChangeDuration)
            .add({
                targets: ".p-index-service__progress .--bullet02 .slider--bullet__progress",
                strokeDashoffset:[140,0],
                easing:"linear",
                duration:sliderDuration
            })
            .add({
                targets: ".p-index-service__progress-title.tit02 .title__border",
                width:[0,'100%'],
                easing:"linear",
                duration:3000
            },'-=4000')
            .add({
                targets: ".p-index-service__progress .--bullet02 .slider--bullet__progress",
                strokeDashoffset:[0,140],
                easing:"easeOutExpo",
                duration:slideChangeDuration
            })
            .add({
                targets: ".p-index-service__progress-title.tit02 .title__border",
                width:['100%',0],
                easing:"linear",
                duration:slideChangeDuration
            },'-=800')
            .add({
                targets: slide02,
                opacity:[1,0],
                duration:slideChangeDuration,
                easing:"easeOutQuart",
                begin(){
                    bgAnime3.restart()
                    progressTit03.classList.add("active");
                    slide03.classList.add("active");
                },
                complete(){
                    bgAnime2.pause()
                    progressTit02.classList.remove("active");
                    slide02.classList.remove("active");
                }
            },'-=' + slideChangeDuration)
            .add({
                targets: ".p-index-service__progress .--bullet03 .slider--bullet__progress",
                strokeDashoffset:[140,0],
                easing:"linear",
                duration:sliderDuration
            })
            .add({
                targets: ".p-index-service__progress-title.tit03 .title__border",
                width:[0,'100%'],
                easing:"linear",
                duration:3000
            },'-=4000')
            .add({
                targets: ".p-index-service__progress .--bullet03 .slider--bullet__progress",
                strokeDashoffset:[0,140],
                easing:"easeOutExpo",
                duration:slideChangeDuration,
            })
            .add({
                targets: ".p-index-service__progress-title.tit03 .title__border",
                width:['100%',0],
                easing:"linear",
                duration:slideChangeDuration
            },'-=800')
            .add({
                targets: slide03,
                opacity:[1,0],
                duration:slideChangeDuration,
                easing:"easeOutQuart",
                begin(){
                    bgAnime4.restart()
                    progressTit04.classList.add("active");
                    slide04.classList.add("active");
                },
                complete(){
                    bgAnime3.pause()
                    progressTit03.classList.remove("active");
                    slide03.classList.remove("active");
                }
            },'-=' + slideChangeDuration)
            .add({
                targets: ".p-index-service__progress .--bullet04 .slider--bullet__progress",
                strokeDashoffset:[140,0],
                easing:"linear",
                duration:sliderDuration
            })
            .add({
                targets: ".p-index-service__progress-title.tit04 .title__border",
                width:[0,'100%'],
                easing:"linear",
                duration:3000
            },'-=4000')
            .add({
                targets: ".p-index-service__progress .--bullet04 .slider--bullet__progress",
                strokeDashoffset:[0,140],
                easing:"easeOutExpo",
                duration:slideChangeDuration,
            })
            .add({
                targets: ".p-index-service__progress-title.tit04 .title__border",
                width:['100%',0],
                easing:"linear",
                duration:slideChangeDuration
            },'-=800')
            .add({
                targets: slide04,
                opacity:[1,0],
                duration:slideChangeDuration,
                easing:"easeOutQuart",
                begin(){
                    bgAnime5.restart()
                    progressTit05.classList.add("active");
                    slide05.classList.add("active");
                },
                complete(){
                    bgAnime4.pause()
                    progressTit04.classList.remove("active");
                    slide04.classList.remove("active");
                }
            },'-=' + slideChangeDuration)
            .add({
                targets: ".p-index-service__progress .--bullet05 .slider--bullet__progress",
                strokeDashoffset:[140,0],
                easing:"linear",
                duration:sliderDuration
            })
            .add({
                targets: ".p-index-service__progress-title.tit05 .title__border",
                width:[0,'100%'],
                easing:"linear",
                duration:3000
            },'-=4000')
            .add({
                targets: ".p-index-service__progress .--bullet05 .slider--bullet__progress",
                strokeDashoffset:[0,140],
                easing:"easeOutExpo",
                duration:slideChangeDuration,
            })
            .add({
                targets: ".p-index-service__progress-title.tit05 .title__border",
                width:['100%',0],
                easing:"linear",
                duration:slideChangeDuration
            },'-=800')
            .add({
                targets: slide01,
                opacity:[0,1],
                duration:slideChangeDuration,
                easing:"easeOutQuart",
                begin(){
                    bgAnime1.restart()
                    progressTit01.classList.add("active");
                    slide01.classList.add("active");
                },
                complete : ()=>{
                    bgAnime5.pause()
                    progressTit05.classList.remove("active");
                    slide05.classList.remove("active");

                    this.LoopAnimation(false)
                }
            },'-=' + slideChangeDuration)
            
            if(seek!==false){
                bgAnime1.restart()
                this.loop.seek(seek)
            }
            this.loop.play()

        }

        let delay = (isOnce) ? 3000:0
        
        setTimeout(() => {
            this.LoopAnimation(false)
            this.isInitHeroSlider = true
        }, delay);

        const burrets = document.querySelectorAll('.js-slider__service--progress')
        if(burrets.length > 0){
            burrets.forEach(el => {
                const i = el.getAttribute("data-index")
                el.addEventListener('click' ,(() => {
                    const activeItems = document.querySelectorAll('.slider--item.active');
                    activeItems.forEach(item => item.classList.remove('active'));
                    this.LoopAnimation( (sliderDuration + slideChangeDuration) * parseInt(i) )
                }).bind(this) )
            })
        }
    }

    worksSlider(){
		new Swiper('.l-works-slider', {
            loop: false,
            // speed:400,
            easing:"linear",
            // loopAdditionalSlides : 3.2,
            slidesPerView: 'auto',
            spaceBetween: 20,
            simulateTouch:true,
            centeredSlides: false,
            grabCursor: true,
        });
    }

    blogSlider(){
        new Swiper('.l-blog-slider', {
            loop: false,
            easing:"linear",
            slidesPerView: 'auto',
            spaceBetween: 20,
            simulateTouch:true,
            centeredSlides: false,
            grabCursor: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            breakpoints: {
                576: {
                    slidesPerView: 'auto',
                    spaceBetween:  40,
                }
            }
        });
    }

    asideSlider(){
        new Swiper('#l-aside-slider', {
            loop: true, 
            slidesPerView: 1.5,
            speed: 6000, 
            allowTouchMove: false,
            autoplay: {
                delay: 0,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2.5,
                },
                992: {
                    slidesPerView: 3,
                }
            }
        });
    }

    serviceAsideSlider(){
        var mySwiper;

        function initSwiper() {
            mySwiper = new Swiper('.p-service__aside--container', {
                loop: false, 
                slidesPerView: 1.2,
                allowTouchMove: true,
                simulateTouch:true,
                spaceBetween: 30,
                breakpoints: {
                    576: {
                        slidesPerView: 1.8,
                    },
                    768: {
                        slidesPerView: 2.2,
                    }
                }
            });
        }

        function destroySwiper() {
            if (mySwiper) {
                mySwiper.destroy();
                mySwiper = null;
            }
        }

        function checkWindowSize() {
            if (window.innerWidth >= 992) {
                destroySwiper();
            } else {
                initSwiper();
            }
        }

        // 初期化時にウィンドウサイズをチェック
        checkWindowSize();

        // ウィンドウリサイズ時にウィンドウサイズをチェック
        window.addEventListener('resize', checkWindowSize);
        
    }

    // serviceListSlider(){
    //     // const serviceList = document.querySelector('.p-service-list')
    //     // console.log(serviceList)
    //     new Swiper('.p-service-list', {
    //         loop: true,
    //         freeMode: true,
    //         effect: "creative",  // 「creative」を指定
    //         direction: "vertical",
    //         creativeEffect: {
    //             prev: {
    //                 // 前のスライドのZ軸（奥行）対して(-400px)を設定しています
    //                 translate: [0, 0, 0],
    //             },
    //             next: {
    //                 // 次のスライドのX軸（左右）対して(100%)を設定しています
    //                 translate: [0, "100%", 0],
    //             },
    //         }
      
    //     });
    // }
    
    pickupSlider(){
        // const pickup = new Swiper('.js-pickup--slider', {
        //     loop: true,
        //     speed:400,
        //     easing:"linear",
        //     loopAdditionalSlides : 5,
        //     slidesPerView: 'auto',
        //     spaceBetween: 20,
        //     simulateTouch:true,
        //     centeredSlides: false,
        //     breakpoints: {
                
        //         576: {
        //             slidesPerView: 'auto',
        //             spaceBetween:  40,
        //         }
        //     }
        // })
    }
    eventSlider(){
        // const SliderContentClass = '.js-event__slider--content'
        // const SliderImageClass = '.js-event__slider--images'
        // const SliderContentNav = document.querySelector(SliderContentClass + ' .swiper--nav')
        // const slides = document.querySelectorAll(SliderImageClass + ' .swiper-slide');
        // if(slides.length > 1){

        //     const EventImageSlider = new Swiper(SliderImageClass, {
        //         effect:'fade',
        //         speed:1500,
        //         easing: "easeInOutExpo",
        //         loop : true
        //     });
        //     const EventContentSlider = new Swiper(SliderContentClass, {
        //         effect:'slide',
        //         navigation:  {
        //             nextEl: SliderContentClass + ' .swiper-button-next',
        //             prevEl: SliderContentClass + ' .swiper-button-prev',
        //         },
                
        //         easing: "easeOutExpo",
        //         speed:1000,
        //         loop : true,
        //         on: {
        //             init: function () {
        //                 SliderContentNav.innerText = 1 + '/' + slides.length
        //             },
        //             slideChange: function () {
        //                 SliderContentNav.innerText = (EventContentSlider.realIndex + 1) + '/' + slides.length
        //             },
        //         },
        //     });

        //     EventImageSlider.controller.control = EventContentSlider;
        //     EventContentSlider.controller.control = EventImageSlider;
        //     EventContentSlider
        // }
    }
}