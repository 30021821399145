// import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        // barba.hooks.afterOnce((data) => {
        //     this.ServiceDetails()
        //     this.ServiceAside()
        // });
        // // barba.hooks.before((data) => {
        // //     gsap.killAll(); // ページ遷移前にアニメーションをリセット
        // // });
        // barba.hooks.after((data) => {
        //     this.ServiceDetails()
        //     this.ServiceAside()
        // });
        // barba.hooks.after((data) => {
        //     setTimeout(() => {
        //         this.resetFixedImages();
        //     }, 500); 
        // });

        this.ServiceDetails()
        this.ServiceAside()
    }
    ServiceDetails() {
        if (window.innerWidth >= 767) {
            const servicePage = document.querySelector(".p-service");
            const serviceSections = document.querySelectorAll(".p-service__details");
            const serviceImgs = document.querySelectorAll(".p-service__details .p-service__bg ,p-service-list__section");
            //const serviceContents = document.querySelectorAll(".p-service__details .p-service__details--content");
            
            if (servicePage) {
                var lastScrollPosition = 0;
                
                for (let i = 0; i < serviceSections.length; i++) {
                    const serviceImg = serviceImgs[i];
                    serviceImg.classList.remove('fixed');
                }

                window.addEventListener('scroll', function() {
                    // console.log('Scrolling...');
                    // console.log('scrollPosition:', scrollPosition);
                    // console.log('lastScrollPosition:', lastScrollPosition);
                    // console.log('rect.top:', rect.top);
                    // console.log('windowHeight:', windowHeight);
                    // console.log('elementOffsetTop:', elementOffsetTop);
                    // console.log('elementHeight:', elementHeight);
                    for (let i = 0; i < serviceSections.length; i++) {
                        const serviceSection = serviceSections[i];
                        const serviceImg = serviceImgs[i];
                        //const serviceContent = serviceContents[i];
                        
                        var rect = serviceImg.getBoundingClientRect();
                        var windowHeight = window.innerHeight;
                            
                        var viewportHeight = window.innerHeight;
                        var elementOffsetTop = serviceSection.getBoundingClientRect().top;
                        var elementHeight = serviceSection.offsetHeight;
            
                        var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            
                        if (scrollPosition < lastScrollPosition) {
                            if (!rect.top <= 0 || rect.bottom >= windowHeight) {
                                //if (elementOffsetTop <= viewportHeight && elementOffsetTop + elementHeight >= viewportHeight) {
                                if (elementOffsetTop <= viewportHeight && elementOffsetTop >= 0) {
                                    serviceImg.classList.remove('fixed');
                                }
                            }
                        } else {
                            if (rect.top <= 0) {
                                serviceImg.classList.add('fixed');
                            }
                        }
                    }
                    lastScrollPosition = scrollPosition;
                });
            }
        }
    }
    ServiceAside() {
        const listWrapperEl = document.querySelector('.p-service__aside--container');
        const listEl = document.querySelector('.p-service__aside--innerwrap');
        const asideEl = document.querySelector('.p-service__aside');
        if(asideEl){
            ScrollTrigger.matchMedia({
                "(min-width: 992px)": function() {
                    gsap.to(listEl, {
                        x: () => -(listEl.clientWidth - listWrapperEl.clientWidth),
                        ease: 'none',
                        scrollTrigger: {
                            trigger: '.p-service__aside',
                            start: 'top -25px', 
                            end: () => `+=${listEl.clientWidth - listWrapperEl.clientWidth}`,
                            scrub: true,
                            pin: true,
                            anticipatePin: 1,
                            invalidateOnRefresh: true,
                        },
                    });
                }
            }); 
        }
    }
    resetFixedImages() {
        const servicePage = document.querySelector(".p-service");
        const serviceImgs = document.querySelectorAll(".p-service__details .p-service__bg ,p-service-list__section");
        if (servicePage) {
            serviceImgs.forEach((serviceImg) => {
                serviceImg.classList.remove('fixed');
                // console.log('Scrolling...');
            });
        }
    }
}
