

// import barba from '@barba/core';

export default class{

	constructor(){

        // barba.hooks.afterOnce((data) => {
            this.init()
        // })
        // barba.hooks.after((data) => {
            // this.init()
        // });
        
    }
    
    init(){
        const companylinks = document.querySelectorAll("[data-company-link]")
        const companyimg01 = document.querySelector("[data-company-img01]")
        const companyimg02 = document.querySelector("[data-company-img02]")

        if(companylinks.length > 0){
            companylinks.forEach(companylink=>{
                const name = companylink.dataset.companyLink
                companylink.addEventListener("mouseenter",()=>{
                    companyimg01.classList.add(name)
                    companyimg02.classList.add(name)
                })
                companylink.addEventListener("mouseleave",()=>{
                    companyimg01.classList.remove(name)
                    companyimg02.classList.remove(name)
                })

            })
        }
    }
}