

// import barba from '@barba/core';

export default class{

	constructor(){

        // barba.hooks.afterOnce((data) => {
            // スクロールイベントを監視して処理を実行
            window.addEventListener('scroll', this.scrollEvent);
        // })
        // barba.hooks.beforeLeave((data) => {

        //     window.removeEventListener('scroll', this.scrollEvent);
        // })
        // barba.hooks.enter((data) => {
        //     setTimeout(() => {
        //         window.addEventListener('scroll', this.scrollEvent);
        //     }, 700); 
        // });
        
    }
    
    // init(){
        
    // }
    scrollEvent(){
        const page = document.getElementById('page');
        const fixedimg = document.getElementById('fixed-img');
        
        const pageHeight = page.scrollHeight;

        // 現在のスクロール位置を取得
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if(fixedimg){
            // スクロール位置に応じてクラスを付与または置き換え
            if (scrollTop >= pageHeight * 0.25 && scrollTop < pageHeight * 0.5) {
                fixedimg.className = 'scroll__quarter';
            } else if (scrollTop >= pageHeight * 0.5 && scrollTop < pageHeight * 0.75) {
                fixedimg.className = 'scroll__half';
            } else if (scrollTop >= pageHeight * 0.75 && scrollTop < pageHeight) {
                fixedimg.className = 'scroll__three-quarter';
            } else if (scrollTop >= pageHeight) {
                fixedimg.className = 'scroll__done';
            } else {
                fixedimg.className = ''; // スクロール位置が条件を満たさない場合はクラスを削除
            }
        }
    }
}